import { IntlProvider } from 'react-intl';
import axios from 'axios';
import { useState, createContext, useMemo, useEffect, startTransition } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import '@formatjs/intl-pluralrules';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/sv';
import '@formatjs/intl-relativetimeformat/locale-data/fi';
import { INTL_FORMATS } from '_js/localizations/locale-data';

const translationFilesClient = axios.create({
  baseURL: 'https://lokalise-translation-files.s3.eu-west-1.amazonaws.com/customerportal/',
  headers: {
    'Content-Type': 'application/json',
  },
});

const fetchTranslatedMessages = async (lang) => {
  const response = await translationFilesClient({
    url: `${lang}.json`,
    method: 'GET',
  });

  return response.data;
};

const defaultRichTextElements = {
  p: (chunks) => <p>{chunks}</p>,
  b: (chunks) => <b>{chunks}</b>,
  u: (chunks) => <u>{chunks}</u>,
  ul: (chunks) => <ul>{chunks}</ul>,
  li: (chunks) => <li>{chunks}</li>,
  i: (chunks) => <i>{chunks}</i>,
  strong: (chunks) => <strong>{chunks}</strong>,
  span: (chunks) => <span>{chunks}</span>,
  br: () => <br />,
};

let initalLanguageCode = '';
try {
  initalLanguageCode = localStorage.getItem('lang') || import.meta.env.VITE_LANGUAGE || 'en';
} catch (e) {
  initalLanguageCode = import.meta.env.VITE_LANGUAGE || 'en';
}

export const LanguageContext = createContext({
  language: initalLanguageCode,
  changeLanguage: () => null,
});

export const LanguageProvider = ({ children = null }) => {
  const [language, setLanguage] = useState(initalLanguageCode);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    fetchTranslatedMessages(language)
      .then((res) => {
        setMessages(res);
      })
      .catch((e) => Sentry.captureException(e));
  }, [language]);

  function changeLanguage(languageCode) {
    startTransition(() => {
      setLanguage(languageCode);
      localStorage.setItem('lang', languageCode);
    });
  }

  const context = useMemo(
    () => ({
      language,
      changeLanguage,
    }),
    [language],
  );

  return (
    <LanguageContext.Provider value={context}>
      <IntlProvider
        wrapRichTextChunksInFragment
        locale={language}
        defaultLocale="en"
        messages={messages}
        defaultRichTextElements={defaultRichTextElements}
        formats={INTL_FORMATS}
        defaultFormats={INTL_FORMATS}
        textComponent="span"
        onError={(err) => {
          if (err.code.toString() === 'MISSING_TRANSLATION') {
            return;
          }

          throw err;
        }}
        onWarn={(warn) => {
          if (warn.includes('pre-compile your messages for performance')) {
            return;
          }
          // This is just for filtering out the warning about pre-compile messages
          // But we still want to see other warnings
          // eslint-disable-next-line no-console
          console.warn(warn);
        }}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node,
};
