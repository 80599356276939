// parent component to import all GR steps
import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import useEffectOnce from '_js/hooks/useEffectOnce';
import { getIdKollenResponse } from '_js/utils/api/API';
import Loader from '_js/components/ui/Loader';
import devices from '_js/styles/Devices';
import { ThankYouComponent } from '_js/pages/NoSelfService';
import APPLICATION_TYPES from '_js/constants/ApplicationTypes';
import { getBrand } from '_js/brands';
import { useLocation } from 'react-router-dom';
import { Section, Layout } from './GjeldsregisteretConsent.styles';
import GjeldsregisteretConsent from './GjeldsregisteretConsent';
import GjeldsregisteretCurrentLoans from './GjeldsregisteretCurrentLoans';
import PiggyBankIcon from '../../../images/piggy-bank.svg?react';
import DigifinansGjeldsregisteret from '../../../images/digifinans-gjeldsregisteret.svg?react';

const LoaderWrapper = styled.div`
  padding: 40% 50% 4rem;

  @media ${devices.desktop} {
    padding: 25% 50% 4rem;
  }
`;

export const getBrandIcon = () => {
  switch (getBrand()) {
    case 'Digifinans':
      return <DigifinansGjeldsregisteret />;
    case 'sambla':
      return <PiggyBankIcon />;
    default:
      return <PiggyBankIcon />;
  }
};

const Gjeldsregisteret = ({ completeStep = () => null }) => {
  const [idKollenStatus, setIdKollenStatus] = useState('loading');
  const [isSignInLoading, setIsSignInLoading] = useState(true);
  const [isGRJourneyFinished, setIsGRJourneyFinished] = useState(false);
  const location = useLocation();

  useEffectOnce(() => {
    const response = async () => {
      try {
        const IdKollenResponse = await getIdKollenResponse();
        setIdKollenStatus(IdKollenResponse.status);
      } catch {
        setIdKollenStatus('failed');
      }
    };
    response();
  });

  useEffect(() => {
    if (idKollenStatus !== 'loading') {
      setIsSignInLoading(false);
    }
  }, [idKollenStatus]);

  if (isSignInLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  if (isGRJourneyFinished) {
    if (location.pathname === '/gjeldsregisteret') {
      return (
        <Layout>
          <Section>
            <ThankYouComponent applicationType={APPLICATION_TYPES.PRIVATE} />
          </Section>
        </Layout>
      );
    }
    completeStep();
  }

  const gjeldsregisteretStep =
    idKollenStatus === 'completed' ? (
      <GjeldsregisteretCurrentLoans setIsGRJourneyFinished={setIsGRJourneyFinished} />
    ) : (
      <GjeldsregisteretConsent setIsGRJourneyFinished={setIsGRJourneyFinished} />
    );

  return gjeldsregisteretStep;
};

Gjeldsregisteret.propTypes = {
  completeStep: PropTypes.func,
};

export default Gjeldsregisteret;
