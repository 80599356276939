import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { APPLICATION, BIDDING } from '_js/constants/States';
import devices from '_js/styles/Devices';
import PPI_STATUS from '_js/constants/PpiStatus';
import { getMarket } from '_js/brands';
import Step from './Step';

const Wrapper = styled.nav`
  margin: 0 auto;
  height: 105px;
  background: var(--breadcrumb-bg-color);
  border-radius: 0 0 20px 20px;
  display: flex;
  padding: 1rem;
  max-width: 30rem;
  min-width: 23rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  ${({ $hasAcceptedBid }) =>
    $hasAcceptedBid &&
    css`
      margin-bottom: 1.5em;
    `}

  @media ${devices.desktop} {
    width: 30rem;
    position: relative;
    top: 0;
    border-radius: 0 0 40px 40px;
  }
`;

const StepperList = styled.ol`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 27rem;
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  position: relative;

  @media ${devices.desktop} {
    max-width: 27rem;
  }

  & li:first-child::before {
    display: block;
    position: absolute;
    right: 50%;
    width: 10rem;
    background-color: var(--breadcrumb-bg-color);
    content: '';
    bottom: 0;
    height: 1.5rem;
    z-index: 2;
  }
  & li:last-child::before {
    display: block;
    position: absolute;
    bottom: 0.65rem;
    right: 50%;
    width: 100rem;
    border-bottom: 0.1rem dashed var(--breadcrumb-line-color);
    content: '';
  }
`;

const InfoText = styled.h2`
  color: var(--breadcrumb-line-active-color);
  font-size: 0.875rem;

  @media ${devices.desktop} {
    font-size: 1rem;
  }
`;

export const Stepper = ({ signed = false }) => {
  const market = getMarket();
  const bids = useSelector((state) => state.bidding.bids);
  const applicationState = useSelector((state) => state.application.state);
  const insuranceEligible = useSelector((state) => state.ppi.insuranceEligible);
  const insuranceStatus = useSelector((state) => state.ppi.status);
  const showPPIPage = useSelector((state) => state.bidding.showPPIPage);
  const biddingState = useSelector((state) => state.bidding.state);
  if (insuranceEligible === undefined && market !== 'no') return null;
  const hasInsurance =
    insuranceStatus === PPI_STATUS.SIGNED || insuranceStatus === PPI_STATUS.EXISTING;
  const showInsuranceStep =
    (insuranceEligible && insuranceStatus !== PPI_STATUS.NOT_ELIGIBLE) || hasInsurance;
  const hasBids = bids.length > 0;
  const hasApplicationOnHold = applicationState === APPLICATION.ON_HOLD || signed;
  const hasAcceptedBid = applicationState === APPLICATION.ACCEPTED || signed;
  const bidding = applicationState === APPLICATION.BIDDING;
  const isAwaitingBids = applicationState === APPLICATION.AWAITING;
  const biddingStarted = biddingState === BIDDING.STARTED;
  const biddingFinished = biddingState === BIDDING.FINISHED;
  const states = {
    optimize: hasApplicationOnHold,
    proposalNoBids: !hasApplicationOnHold && (bidding || isAwaitingBids) && !hasBids,
    proposalBiddingStarted: !hasApplicationOnHold && bidding && hasBids && biddingStarted,
    proposalBiddingFinished: !hasApplicationOnHold && bidding && hasBids && biddingFinished,
    insurance: !hasApplicationOnHold && hasAcceptedBid && showPPIPage,
    sign: !hasApplicationOnHold && hasAcceptedBid && !showPPIPage,
  };

  return (
    <Wrapper
      $hasAcceptedBid={hasAcceptedBid}
      aria-label={<FormattedMessage id="stepper.progress.label" defaultMessage="progress" />}
    >
      <StepperList>
        <Step done>
          <FormattedMessage
            id="breadcrumbList.apply.2"
            defaultMessage="Apply"
            description="First element in the breadcrumb (the progress stepper)"
            tagName=""
          />
        </Step>

        <Step done={!states.optimize} active={states.optimize} number={2}>
          <FormattedMessage
            id="breadcrumbList.optimize.2"
            defaultMessage="Optimize"
            description="Second element in the breadcrumb (the progress stepper)"
            tagName=""
          />
        </Step>

        <Step
          done={hasAcceptedBid}
          active={
            states.proposalNoBids || states.proposalBiddingStarted || states.proposalBiddingFinished
          }
          number={3}
        >
          <FormattedMessage
            id="breadcrumbList.proposal"
            defaultMessage="Proposal"
            description="Third element in the breadcrumb (the progress stepper)"
            tagName=""
          />
        </Step>
        {showInsuranceStep && (
          <Step done={hasInsurance || !showPPIPage} active={states.insurance} number={4}>
            <FormattedMessage
              id="breadcrumbList.insurance"
              defaultMessage="Insurance"
              description="Insurance step in breadcrumb"
              tagName=""
            />
          </Step>
        )}

        <Step active={states.sign} number={showInsuranceStep ? 5 : 4}>
          <FormattedMessage
            id="breadcrumbList.sign.2"
            defaultMessage="Sign"
            description="Last element in the breadcrumb (the progress stepper)"
            tagName=""
          />
        </Step>
      </StepperList>
      <InfoText>
        {states.optimize && (
          <FormattedMessage
            id="breadcrumbList.dynamicText.optimize"
            defaultMessage="Improve to get the best loan proposals for you!"
            description="Dynamic text for breadcrumb"
            tagName=""
          />
        )}
        {states.proposalNoBids && (
          <FormattedMessage
            id="breadcrumbList.dynamicText.proposalnobids"
            defaultMessage="Application sent in. Await proposals."
            description="Dynamic text for breadcrumb"
            tagName=""
          />
        )}
        {states.proposalBiddingStarted && (
          <FormattedMessage
            id="breadcrumbList.dynamicText.proposalbiddingstarted"
            defaultMessage="You got your first proposals! Await the rest or pick."
            description="Dynamic text for breadcrumb"
            tagName=""
          />
        )}
        {states.proposalBiddingFinished && (
          <FormattedMessage
            id="breadcrumbList.dynamicText.proposalbiddingfinished"
            defaultMessage="All proposals received! Pick the best one for you."
            description="Dynamic text for breadcrumb"
            tagName=""
          />
        )}
        {states.insurance && (
          <FormattedMessage
            id="breadcrumbList.dynamicText.insurance"
            defaultMessage="If you are insured, we help if something happens."
            description="Dynamic text for breadcrumb"
            tagName=""
          />
        )}
        {states.sign && (
          <FormattedMessage
            id="breadcrumbList.dynamicText.sign"
            defaultMessage="Sign your loan and get your money to your account."
            description="Dynamic text for breadcrumb"
            tagName=""
          />
        )}
      </InfoText>
    </Wrapper>
  );
};

Stepper.propTypes = {
  signed: PropTypes.bool,
};

export default Stepper;
