import PropTypes from 'prop-types';
import classNames from 'classnames';

import CheckIcon from '../../../images/check.svg?react';

const BreadcrumbItem = ({ done = false, active = false, number = 1, children = null }) => (
  <li
    className={classNames({
      'c-breadcrumb-item': true,
      'c-breadcrumb-item--done': done,
    })}
  >
    <div className="c-breadcrumb-item__content">
      <span
        className={classNames({
          'c-breadcrumb-item__title': true,
          'c-breadcrumb-item__title--active': active,
        })}
      >
        {children}
      </span>

      <div
        className={classNames({
          'c-breadcrumb-item__circle': true,
          'c-breadcrumb-item__circle--done': done,
          'c-breadcrumb-item__circle--active': active,
        })}
      >
        <div className="c-breadcrumb-item__icon">
          {done ? <CheckIcon className="u-image--auto-scale u-svg--fill-white" /> : number}
        </div>
      </div>
    </div>
  </li>
);

BreadcrumbItem.propTypes = {
  done: PropTypes.bool,
  active: PropTypes.bool,
  number: PropTypes.number,
  children: PropTypes.node,
};

export default BreadcrumbItem;
