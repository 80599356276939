import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';

import HeaderText from '_js/components/molecules/HeaderText';
import ApplicationSummary from '_js/components/molecules/ApplicationSummary';
import ExpirationWarningMessage from '_js/containers/ExpirationWarningMessage';

import { sendEvent } from '_js/utils/3rdparty/gtm';
import Routes from '_js/constants/Routes';
import { APPLICATION } from '_js/constants/States';
import { useSelector } from 'react-redux';
import BiddingStateWrapper from './BiddingStateWrapper';
import ChevronArrow from '../../../../images/chevron-arrow.svg?react';

const Button = styled.button`
  color: ${(props) => props.theme.styled.arrowButton};
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 1em;
  margin: auto;
  font-family: var(--wallet-font-family-standard), sans-serif;
`;

const ChevronArrowWrapper = styled(ChevronArrow)`
  width: 1.5rem;
  height: 1.5rem;
  transform: ${(props) => (props.$showInfo ? 'rotate(180deg)' : '')};
`;

const messages = defineMessages({
  chooseOffer: {
    id: 'biddingStateDisplay.title.choose',
    defaultMessage: 'Choose loan offer: ',
    description: 'Tells customer to choose one of the loan offers',
  },
  bidAccepted: {
    id: 'biddingStateDisplay.bidAccepted',
    defaultMessage: 'Sign and complete',
    description: 'Sign and finish/complete',
  },
  awaitingBids: {
    id: 'awaitingBids.header',
    defaultMessage: 'Thank you, your application has been received.',
    description: 'State of your application - The application is receiced an awaiting for bids',
  },
  awaitingAdditionalBids: {
    id: 'awaitingAdditionalBids.header',
    defaultMessage: 'Application is processed',
    description: 'The application is awaiting further bids',
  },
  searched: {
    id: 'applicationSummary.applicationBoundaryPrefix',
    defaultMessage: 'My application',
    description: 'Shows the amount and repayment time of the applied loan',
  },
});

const BiddingStateDisplay = ({
  biddingFinished = false,
  bidAccepted = false,
  awaitingBids = false,
  repaymentTime = null,
  totalAmount = null,
  noBids = null,
}) => {
  const [showInfo, setShowInfo] = useState(awaitingBids);
  const applicationId = useSelector((state) => state.application.id);

  const toggleInfo = () => {
    setShowInfo(!showInfo);

    sendEvent({
      event: 'user_moreInfo_click',
      event_source: 'web',
      content_id: 'biddingStateDisplay.moreInfo.button.text',
      window_location: Routes[APPLICATION.HAS_BIDS].path,
      application_id: applicationId,
    });
  };

  const doNotShow = noBids || awaitingBids || bidAccepted;

  let biddingStateText;
  if (awaitingBids) {
    biddingStateText = messages.awaitingBids;
  } else if (bidAccepted) {
    biddingStateText = messages.bidAccepted;
  } else if (biddingFinished) {
    biddingStateText = messages.chooseOffer;
  } else {
    biddingStateText = messages.awaitingAdditionalBids;
  }

  const biddingStatus = () => {
    if (awaitingBids) {
      return (
        <FormattedMessage
          id="biddingStateDisplay.state.application.received"
          description="Short text saying that the state of the application is received"
          defaultMessage="Application completed"
        />
      );
    }
    if (biddingFinished) {
      return (
        <FormattedMessage
          id="biddingStateDisplay.state.application.finished"
          description="Short text saying that the state of the application is finished (bid not accepted)"
          defaultMessage="Done - choose"
        />
      );
    }
    if (noBids) {
      return (
        <FormattedMessage
          id="biddingStateDisplay.state.application.noBids"
          description="Short text saying that the state of the application is finished"
          defaultMessage="Done"
        />
      );
    }
    return (
      <FormattedMessage
        id="biddingStateDisplay.state.application.onGoing"
        description="Short text saying that the state of the application is on going"
        defaultMessage="Application is processed"
      />
    );
  };

  return (
    <BiddingStateWrapper>
      {!bidAccepted ? (
        <Button onClick={toggleInfo}>
          <FormattedMessage
            id="biddingStateDisplay.moreInfo.button.text"
            description="By clicking this button the customer will show more information about their loan"
            defaultMessage="More info"
          />
          <ChevronArrowWrapper $showInfo={showInfo} />
        </Button>
      ) : null}
      {showInfo && (
        <ApplicationSummary
          repaymentTime={repaymentTime}
          totalAmount={totalAmount}
          text={messages.searched}
          status={biddingStatus()}
          noBids={noBids}
        />
      )}
      <ExpirationWarningMessage />
      {!doNotShow ? (
        <HeaderText
          headline={biddingStateText}
          animatedEllipsis={biddingStateText === messages.awaitingAdditionalBids}
          marginTop="0"
          justifyContent="center"
        />
      ) : null}
    </BiddingStateWrapper>
  );
};

BiddingStateDisplay.propTypes = {
  biddingFinished: PropTypes.bool,
  bidAccepted: PropTypes.bool,
  awaitingBids: PropTypes.bool,
  repaymentTime: PropTypes.number,
  totalAmount: PropTypes.number,
  noBids: PropTypes.bool,
};

export { BiddingStateWrapper };
export default BiddingStateDisplay;
