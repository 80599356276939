import { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { IfFeatureEnabled } from '@growthbook/growthbook-react';
import classNames from 'classnames';
import { Container, Box, Title } from '@mantine/core';

import QandA from '_js/constants/QandA';
import isMobile from '_js/utils/isMobile';
import { FAQ_FEATURE } from '_js/localizations/config/features';
import devices from '_js/styles/Devices';
import Arrow from '../../../images/expand-arrow.svg?react';

const activeStyles = `
  background: var(--details-summary-color);
  border-radius: 5px;
  color: var(--text-font-color);
  font-weight: normal;
`;

const DetailsDiv = styled.div`
  border-bottom: solid 1px var(--off-white-tint-50);
  transition: border-color 0.2s;

  @media ${devices.desktop} {
    border-color: ${(props) => (props.$questionSelected ? 'transparent' : 'none')};
  }
`;

export const Button = styled.button`
  display: flex;
  background-color: transparent;
  background-image: none;
  border: 0;
  border-radius: 5px;
  color: var(--dark-teal);
  cursor: pointer;
  font-weight: normal;
  text-decoration: none;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0.5em;
  transition: background 0.2s, color 0.2s;
  width: 100%;
  font-size: 16px;
  font-family: var(--wallet-font-family-standard), serif;

  @media ${devices.desktop} {
    &:active {
      ${activeStyles}
    }

    ${(props) =>
      props.$questionSelected &&
      css`
        ${activeStyles}
      `}
  }

  @media ${devices.smallerThanDesktop} {
    &:active {
      padding-bottom: 0.5em;
      font-weight: 700;
    }

    ${(props) =>
      props.$questionSelected &&
      css`
        padding-bottom: 0.5em;
        font-weight: 700;
      `}
  }
`;

const StyledArrow = styled(Arrow)`
  display: flex;
  color: ${(props) => props.theme.colors.arrow.color};
  transition: transform 0.2s;
  transform: ${(props) => (props.$questionSelected ? 'rotate(90deg);' : '')};

  path {
    stroke: ${(props) => props.theme.colors.arrow.chevron};
  }

  @media ${devices.desktop} {
    transform: rotate(0deg);
  }
`;

const StyledDD = styled.dd`
  font-family: var(--wallet-font-family-standard), serif;
  display: ${(props) => (props.$questionSelected ? 'block' : 'none')};
  margin: 0;
  margin-right: 2em;
  padding-bottom: 0.5em;
  padding-left: ${(props) => (props.id === 'faq-addCurrentLoan' ? 0 : '0.5em')};

  @media ${devices.desktop} {
    display: none;
  }

  > * {
    margin-bottom: 0.5em;
    margin-top: 0;
  }
`;

const SelectedQuestionContainer = styled.div`
  @media ${devices.desktop} {
    display: ${(props) => (props.$active ? 'block' : 'none')};
  }

  background: var(--off-white-tint-50);
  border-radius: 5px;
  margin: 0;
  padding: 2em;
  flex: ${(props) => (props.$active ? 1 : 'auto')};
  display: none;
`;

const FAQ = ({ qnaSet, filteredQuestions = undefined }) => {
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

  const selectQuestion = (renderIndex) => {
    if (isMobile() && renderIndex === selectedQuestionIndex) {
      setSelectedQuestionIndex(null);
    } else {
      setSelectedQuestionIndex(renderIndex);
    }
  };
  let questionSet = QandA[qnaSet];

  if (questionSet !== undefined) {
    const selectedQuestion = questionSet[selectedQuestionIndex];

    if (filteredQuestions) {
      questionSet = questionSet.filter((question, i) => question.id !== filteredQuestions[i]);
    }

    return (
      <IfFeatureEnabled feature={FAQ_FEATURE}>
        <Box bg="white">
          <Container mt="xl" pb="xl" maw={{ base: 500, md: 1200 }}>
            <Title order={2} pt="xl" mb="xl">
              <FormattedMessage id="faq.heading" defaultMessage="Common questions and answers" />
            </Title>
            <Box display={{ md: 'flex' }}>
              <dl>
                {questionSet.map(({ id, question, answer }, renderIndex) => {
                  const faqId = `faq-${id}`;
                  const questionSelected = selectedQuestionIndex === renderIndex;

                  return (
                    <DetailsDiv $questionSelected={questionSelected} key={id}>
                      <dt>
                        <Button
                          type="submit"
                          onClick={() => selectQuestion(renderIndex)}
                          $questionSelected={questionSelected}
                          aria-controls={faqId}
                          aria-expanded={questionSelected}
                        >
                          <span className="o-question-list__text">{question}</span>
                          <StyledArrow
                            $questionSelected={questionSelected}
                            height="22"
                            width="22"
                          />
                        </Button>
                      </dt>
                      <StyledDD
                        id={faqId}
                        $questionSelected={questionSelected}
                        role="region"
                        tabIndex="-1"
                      >
                        {answer}
                      </StyledDD>
                    </DetailsDiv>
                  );
                })}
              </dl>

              <SelectedQuestionContainer className={classNames()} $active={selectedQuestion}>
                {selectedQuestion && (
                  <>
                    <h4 className="c-heading--small-top-margin">
                      {qnaSet !== 'addCurrentLoans' && selectedQuestion.question}
                    </h4>
                    {selectedQuestion.answer}
                  </>
                )}
              </SelectedQuestionContainer>
            </Box>
          </Container>
        </Box>
      </IfFeatureEnabled>
    );
  }
  return null;
};

FAQ.propTypes = {
  qnaSet: PropTypes.oneOfType([PropTypes.string.isRequired]).isRequired,
  filteredQuestions: PropTypes.arrayOf(PropTypes.string),
};

export default FAQ;
