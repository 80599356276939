import PropTypes from 'prop-types';
import { Loader, Wrapper } from './Button.styles';

const Button = ({
  disabled = false,
  children = null,
  onClick = () => {},
  sticky = false,
  primary = false,
  outline = false,
  fullWidth = false,
  href = null,
  isLoading = false,
  ...props
}) => {
  const isDisabled = disabled || isLoading;
  const handleClick = isDisabled ? null : onClick;

  return (
    <Wrapper
      as={href ? 'a' : 'button'}
      onClick={handleClick}
      disabled={isDisabled}
      $sticky={sticky}
      $primary={primary}
      $outline={outline}
      $fullWidth={fullWidth}
      href={href}
      {...props}
    >
      {isLoading && <Loader />}
      {children}
    </Wrapper>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  sticky: PropTypes.bool,
  outline: PropTypes.bool,
  primary: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
