import { GrowthBook } from '@growthbook/growthbook-react';
import { useSelector } from 'react-redux'; // Add this import

import { getBrand, getMarket } from '_js/brands';
import { getCookie } from '_js/utils/cookies';
import { useEffect, useMemo, useState, useRef } from 'react';
import { isDevelopmentOrStaging } from '../environment';

const getDeviceType = () => (/Mobi/i.test(navigator.userAgent) ? 'mobile' : 'desktop');

const hasStatisticsConsent = () => {
  const consentCookie = document.cookie;
  return consentCookie?.includes('statistics:true');
};

export const useSetupGrowthbook = () => {
  const externalId = getCookie('external_id');
  const applicationId = useSelector((state) => state.auth.applicationId);
  const customerId = useSelector((state) => state.auth.customerId);
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const viewedExperiments = useRef(new Set());
  const dataLayerEventsPushed = useRef(new Set());
  const experimentResults = useRef(new Map());
  const growthbook = useMemo(
    () =>
      new GrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey: import.meta.env.VITE_GROWTHBOOK_SDK_KEY,
        enableDevMode: isDevelopmentOrStaging(),
        attributes: {
          application_id: applicationId || '',
          anonymous_id: externalId,
          customer_id: customerId || '',
          brand: getBrand(),
          market: getMarket(),
          device: getDeviceType(),
          url: typeof window !== 'undefined' ? window.location.href : '',
        },
        trackingCallback: (experiment, result) => {
          if (!viewedExperiments.current.has(experiment.key)) {
            viewedExperiments.current.add(experiment.key);
            experimentResults.current.set(experiment.key, result);
          }
          if (hasStatisticsConsent() && !dataLayerEventsPushed.current.has(experiment.key)) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'experiment_viewed',
              experiment_id: experiment.key,
              variation_id: result.key,
              anonymous_id: externalId,
              application_id: applicationId,
              customer_id: customerId,
            });
            dataLayerEventsPushed.current.add(experiment.key);
          }
        },
      }),
    [externalId, applicationId, customerId],
  );

  useEffect(() => {
    growthbook?.init({ streaming: isDevelopmentOrStaging() }).then(() => {
      setFeaturesLoaded(true);
    });
  }, [growthbook]);

  useEffect(() => {
    const onConsentChanged = () => {
      if (hasStatisticsConsent() && featuresLoaded) {
        viewedExperiments.current.forEach((experimentKey) => {
          if (!dataLayerEventsPushed.current.has(experimentKey)) {
            const result = experimentResults.current.get(experimentKey);
            if (result) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'experiment_viewed',
                experiment_id: experimentKey,
                variation_id: result.key,
                anonymous_id: externalId,
                application_id: applicationId,
                customer_id: customerId,
              });
              dataLayerEventsPushed.current.add(experimentKey);
            }
          }
        });
      }
    };

    window.addEventListener('CookiebotOnConsentReady', onConsentChanged);
    window.addEventListener('CookiebotOnAccept', onConsentChanged);
    window.addEventListener('CookiebotOnDecline', onConsentChanged);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', onConsentChanged);
      window.removeEventListener('CookiebotOnAccept', onConsentChanged);
      window.removeEventListener('CookiebotOnDecline', onConsentChanged);
    };
  }, [featuresLoaded, externalId, applicationId, customerId]);

  return growthbook;
};

export const updateGrowthBookAttributes = (growthbook, attributes) => {
  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    ...attributes,
  });
};
