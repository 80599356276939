import { lazy } from 'react';
import { APPLICATION } from '_js/constants/States';

const AcceptedBid = lazy(() => import('../pages/AcceptedBid'));
const ApplicationFailed = lazy(() => import('../pages/ApplicationFailed'));
const AwaitingBids = lazy(() => import('../pages/AwaitingBids'));
const BidPage = lazy(() => import('../pages/BidPage'));
const LoanOffers = lazy(() => import('../pages/LoanOffers'));
const NoBids = lazy(() => import('../pages/noBids/NoBids'));
const MortgageInterestPage = lazy(() => import('../pages/MortgageInterestPage'));
const SuccessfulLogin = lazy(() => import('../pages/SuccessfulLogin'));
const Unauthorized = lazy(() => import('../pages/Login'));
const ApplicationOnHold = lazy(() => import('../pages/ApplicationOnHold'));
const Deactivated = lazy(() => import('../pages/Deactivated'));
const PPIIntermediatePage = lazy(() => import('../pages/PPIIntermediatePage'));
const NoSelfService = lazy(() => import('../pages/NoSelfService'));
const AutogiroRedirectPayment = lazy(() =>
  import('../components/autogiroLink/AutogiroRedirectPayment'),
);
const ChooseInsurancePage = lazy(() =>
  import('../pages/insurancePageFlow/chooseInsurancePage/ChooseInsurancePage'),
);
const MultipleApplicationsPage = lazy(() => import('../pages/Applications'));

const Routes = {
  [APPLICATION.ACCEPTED]: {
    url: '/application/:applicationId/accepted-loan-offer/:bidId',
    path: '/accepted-loan-offer/:bidId',
    exactUrlRegex: '^/application/[0-9]+/accepted-loan-offer/[0-9]+$',
    component: AcceptedBid,
  },
  [APPLICATION.MULTIPLE_APPLICATIONS_OVERVIEW]: {
    url: '/applications',
    path: '/applications',
    exactUrlRegex: '^/applications+$',
    component: MultipleApplicationsPage,
  },
  [APPLICATION.ON_HOLD]: {
    url: '/application/:applicationId/paused',
    path: '/paused',
    exactUrlRegex: '^/application/[0-9]+/paused$',
    component: ApplicationOnHold,
  },
  [APPLICATION.AWAITING]: {
    url: '/application/:applicationId/processing-application',
    path: '/processing-application',
    exactUrlRegex: '^/application/[0-9]+/processing-application$',
    component: AwaitingBids,
  },
  [APPLICATION.DEACTIVATED]: {
    url: '/application/:applicationId/deactivated',
    path: '/deactivated',
    exactUrlRegex: '^/application/[0-9]+/deactivated$',
    component: Deactivated,
  },
  [APPLICATION.FAILED]: {
    url: '/application/:applicationId/failed',
    path: '/failed',
    exactUrlRegex: '^/application/[0-9]+/failed',
    component: ApplicationFailed,
  },
  [APPLICATION.HAS_BIDS]: {
    url: '/application/:applicationId/loan-offers',
    path: '/loan-offers',
    exactUrlRegex: '^/application/[0-9]+/loan-offers$',
    component: LoanOffers,
  },
  [APPLICATION.NO_BIDS]: {
    url: '/application/:applicationId/no-loan-offers',
    path: '/no-loan-offers',
    exactUrlRegex: '^/application/[0-9]+/no-loan-offers',
    component: NoBids,
  },
  [APPLICATION.MORTGAGE_INTEREST]: {
    url: '/application/:applicationId/mortgage-interest',
    path: '/mortgage-interest',
    exactUrlRegex: '^/application/[0-9]+/mortgage-interest',
    component: MortgageInterestPage,
  },
  [APPLICATION.INSURANCE]: {
    url: '/application/:applicationId/insurance',
    path: '/insurance',
    exactUrlRegex: '^/application/[0-9]+/insurance+$',
    component: ChooseInsurancePage,
  },
  [APPLICATION.PPI_SIGNING]: {
    url: '/application/:applicationId/ppi-signing',
    path: '/ppi-signing',
    exactUrlRegex: '^/application/[0-9]+/ppi-signing+$',
    component: PPIIntermediatePage,
    heading: 'ppiSigninPage',
    static: true,
  },
  [APPLICATION.PPI_DIRECT_DEBT]: {
    url: '/application/:applicationId/autogiro',
    path: '/autogiro',
    exactUrlRegex: '^/application/[0-9]+/autogiro+$',
    component: AutogiroRedirectPayment,
    heading: 'autogiroRedirectPayment',
    static: true,
  },
  PPISigningRedirect: {
    url: '/application/:applicationId/sign-ppi',
    path: '/sign-ppi',
    exactUrlRegex: '^/application/[0-9]+/sign-ppi+$',
    component: PPIIntermediatePage,
    heading: 'ppiSigningPageRedirect',
  },
  ApplicationManager: {
    url: '/application/:applicationId',
    path: '/application/:applicationId/*',
  },
  BidPage: {
    url: '/application/:applicationId/loan-offers/:bidId',
    path: '/loan-offers/:bidId',
    exactUrlRegex: '^/application/[0-9]+/loan-offers/[0-9]+$',
    component: BidPage,
    static: true,
  },
  DeactivatedBids: {
    url: '/application/:applicationId/deactivated-offers',
    path: '/deactivated-offers',
    exactUrlRegex: '^/application/[0-9]+/deactivated-offers$',
    component: LoanOffers,
    static: true,
  },
  NoSelfService: {
    url: '/application/:applicationId/thank-you',
    path: '/thank-you',
    exactUrlRegex: '^/application/[0-9]+/thank-you$',
    component: NoSelfService,
  },
  InternalLoginSuccessful: {
    url: '/internal-login-successful',
    path: '/internal-login-successful',
    exactUrlRegex: '^(/internal-login-successful|.*\\?.*login=internal)',
    component: SuccessfulLogin,
  },
  Unauthorized: {
    url: '/log-in',
    path: '/log-in',
    exactUrlRegex: '^/log-in',
    component: Unauthorized,
  },
};

export default Routes;
