import { FormattedMessage } from 'react-intl';
import { FormattedNumberWithFallback } from '_js/utils/FormattedNumberWithFallback';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { LabelValueRowWithBorderBottom, BoldText } from './GjeldsregisteretCurrentLoans.styles';

const StyledLabel = styled.span`
  text-align: left;
`;

export default function GjeldsregisteretChargeCard({ loan = null }) {
  return (
    <>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.financialInstitutionName"
            defaultMessage="Bank"
            description="A label for the financial institution name"
          />
        </StyledLabel>
        <BoldText>{loan?.financialInstitutionName?.toLowerCase()}</BoldText>
      </LabelValueRowWithBorderBottom>

      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em" data-testid="nonInterestBearingBalance">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.nonInterestBearingBalance"
            defaultMessage="Non Interest Bearing Balance"
            description="A label for the Non interest Bearing Balance"
          />
        </StyledLabel>
        <BoldText>
          <FormattedNumberWithFallback
            value={loan.nonInterestBearingBalance / 100}
            formatAs="currency"
          />
        </BoldText>
      </LabelValueRowWithBorderBottom>

      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.interestBearingBalance"
            defaultMessage="Interest Bearing Balance"
            description="A label for the Interest Bearing Balance"
          />
        </StyledLabel>
        <BoldText>
          <FormattedNumberWithFallback value={loan.interestBearingBalance} formatAs="currency" />
        </BoldText>
      </LabelValueRowWithBorderBottom>

      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.coBorrower"
            defaultMessage="Co-borrower"
            description="A label for the co borrower"
          />
        </StyledLabel>
        <BoldText>
          {loan?.coBorrower === 'none' ? (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.coBorrowerValue.none"
              defaultMessage="None"
              description="The value of co borrower"
            />
          ) : (
            <FormattedMessage
              id="gjeldsregisteret.current.loans.moreInfo.coBorrowerValue.exists"
              defaultMessage="Exists"
              description="The value of co borrower"
            />
          )}
        </BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.processedTime"
            defaultMessage="Last updated from creditor"
            description="A label for the processed time"
          />
        </StyledLabel>
        <BoldText>{format(new Date(loan?.processedTime), 'dd/MM/yy')}</BoldText>
      </LabelValueRowWithBorderBottom>
      <LabelValueRowWithBorderBottom pt="0.6em" pb="0.6em">
        <StyledLabel>
          <FormattedMessage
            id="gjeldsregisteret.current.loans.moreInfo.receivedTime"
            defaultMessage="Last updated from gjeldsregistret"
            description="A label for the receieved time"
          />
        </StyledLabel>
        <BoldText>{format(new Date(loan?.receivedTime), 'dd/MM/yy')}</BoldText>
      </LabelValueRowWithBorderBottom>
    </>
  );
}

GjeldsregisteretChargeCard.propTypes = {
  loan: PropTypes.shape({
    id: PropTypes.number,
    balance: PropTypes.number,
    coBorrower: PropTypes.string,
    consentId: PropTypes.number,
    createdAt: PropTypes.string,
    financialInstitutionId: PropTypes.string,
    financialInstitutionName: PropTypes.string,
    installmentChargePeriod: PropTypes.string,
    installmentCharges: PropTypes.number,
    interestBearingBalance: PropTypes.number,
    loanType: PropTypes.string,
    nominalInterestRate: PropTypes.number,
    nonInterestBearingBalance: PropTypes.number,
    originalBalance: PropTypes.number,
    processedTime: PropTypes.string,
    providerId: PropTypes.string,
    receivedTime: PropTypes.string,
    type: PropTypes.number,
    updatedAt: PropTypes.string,
    terms: PropTypes.number,
  }),
};
