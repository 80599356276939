import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { getBrandConstants, getBrand, SAMBLA, ADVISA, DIGIFINANS } from '_js/brands';
import block from '_js/utils/styling/BEM';
import useTimeout from '_js/hooks/useTimeout';
import StatusMessage from '_js/components/organisms/StatusMessage';
import { StyledSamblaLogo, DigifinansLoading, AdvisaLoading } from './animations';

const loadingBlock = block('c-loading');
const ReloadButton = (chunks) => (
  <button
    type="submit"
    className={classNames(
      'c-button--unstyled',
      'c-link',
      'c-link--dark-teal',
      'c-link--underline',
      'c-text--bold',
    )}
    onClick={() => window.location.reload()}
  >
    {chunks}
  </button>
);
const phoneNumber = getBrandConstants().phone.number;
const CallButton = (chunks) => (
  <a className={classNames('c-link', 'c-link--dark-teal')} href={`tel:${phoneNumber}`}>
    {chunks}
  </a>
);

const BounceLogo = ({ bouncer = '', bounce = '' }) => (
  <div className={bouncer}>
    <div className={bounce} />
    <div className={bounce} />
    <div className={bounce} />
  </div>
);

const LoadingLogo = ({ classes = { bounce: '', bouncer: '', loading: '' } }) => {
  const brand = getBrand();
  switch (brand) {
    case ADVISA:
      return <AdvisaLoading />;
    case SAMBLA:
      return <StyledSamblaLogo />;
    case DIGIFINANS:
      return <DigifinansLoading />;
    default:
      return <BounceLogo {...classes} />;
  }
};
const Loading = ({ isComponent = false, children = '' }) => {
  const modifiers = isComponent ? [] : ['page'];
  const classes = {
    loading: loadingBlock(modifiers),
    bouncer: loadingBlock('bouncer'),
    bounce: loadingBlock('bounce', modifiers),
  };

  const isTakingLongTime = useTimeout(10000);

  return (
    <div className={classes.loading} role="alert" aria-busy="true">
      <div>
        <LoadingLogo classes={classes} />
        <div>{children}</div>
        {isTakingLongTime && (
          <StatusMessage state="warning">
            <FormattedMessage
              id="loading.long.takingLongerTimeThanUsual"
              defaultMessage="This seems to take longer than expected..."
              description="Loading screen message that appears after 10 seconds of customer being stuck in a loading state"
              tagName="p"
            />
            <FormattedMessage
              id="loading.long.resolveStuckLoading"
              defaultMessage="If nothing happens, try <reload>loading the page</reload> or <call>contact customer service</call>."
              description="Provides the customer with actions after having been stuck on loading screen for 10 seconds"
              values={{
                reload: ReloadButton,
                call: CallButton,
              }}
              tagName="p"
            />
          </StatusMessage>
        )}
      </div>
    </div>
  );
};

LoadingLogo.propTypes = {
  classes: PropTypes.shape({
    bounce: PropTypes.string,
    bouncer: PropTypes.string,
    loading: PropTypes.string,
  }),
};
BounceLogo.propTypes = {
  bouncer: PropTypes.string,
  bounce: PropTypes.string,
};
Loading.propTypes = {
  isComponent: PropTypes.bool,
  children: PropTypes.node,
};

export default Loading;
