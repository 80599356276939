import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.a`
  color: var(--dark-teal);
  ${({ disabled }) =>
    disabled &&
    css`
      text-decoration: none;
      color: var(--border-grey);
      cursor: not-allowed;
    `}
`;

const Link = ({ children = null, onClick = () => {}, href = '', disabled = false, ...props }) => {
  const handleClick = disabled
    ? (e) => {
        e.preventDefault();
      }
    : onClick;

  return (
    <Wrapper href={href} onClick={handleClick} disabled={disabled} {...props}>
      {children}
    </Wrapper>
  );
};

Link.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  href: PropTypes.string,
};

export default Link;
